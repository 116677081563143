import React, { useCallback } from 'react';
import { ACTIONTYPE, ConferencingStatus } from '../AppStateProvider';
import axios from 'axios';
import { HEALTH_URL_BOOKING_API, HEALTH_URL_CONTROL_API, HEALTH_URL_PHONE_NUMBER_API } from '../../../constants';

export default function useConferencingStatus(dispatch: React.Dispatch<ACTIONTYPE>) {

  const getApiStatus = useCallback(() => {
    return Promise.allSettled([
      axios.get(HEALTH_URL_BOOKING_API),
      axios.get(HEALTH_URL_CONTROL_API),
      axios.get(HEALTH_URL_PHONE_NUMBER_API),
    ])
      .then((responses) => {
        const [bookingApiStatus, conferenceApiStatus, phoneNumberApiStatus] = responses;

        const conferencingStatusObj: ConferencingStatus = {
          bookingApiStatus: bookingApiStatus.status === 'fulfilled' ? 'operational' : '',
          conferenceApiStatus: conferenceApiStatus.status === 'fulfilled' ? 'operational' : '',
          phoneNumberApiStatus: phoneNumberApiStatus.status === 'fulfilled' ? 'operational' : '',
        };

        dispatch({ type: 'set-conferencing-status', statusObj: conferencingStatusObj });

        const failedRequests = responses.filter((r) => r.status === 'rejected');

        if (failedRequests.length) {
          dispatch({ type: 'set-conferencing-status-error', error: (failedRequests[0] as any).reason });
        }
      })
      .catch((error) => {
        dispatch({ type: 'set-conferencing-status-error', error });
      });
  }, [dispatch]);

  return { getConferencingStatus: getApiStatus } as const;
}
