import { default as appInfo } from '../package.json';

declare global {
  interface Window {
    TWILIO_ACCOUNT_SID: string;
    TWILIO_API_KEY_SID: string;
    TWILIO_API_KEY_SECRET: string;

    BOOKING_API_URL: string;
    CONFERENCE_CONTROL_API_URL: string;
    PHONE_NUMBER_API_URL: string;
  }
}

export const APP_NAME = appInfo.name;

export const AUDIO_LEVEL_THRESHOLD = 200;

export const AUDIO_LEVEL_STANDARD_DEVIATION_THRESHOLD = AUDIO_LEVEL_THRESHOLD * 0.05; // 5% threshold

export const INPUT_TEST_DURATION = 20000;

export const RECORD_DURATION = 4000;

export const FF_BITRATE_REPORT_ENABLED = false;

export const ACCOUNT_SID = window.TWILIO_ACCOUNT_SID;
export const TWILIO_API_KEY_SID = window.TWILIO_API_KEY_SID;
export const TWILIO_API_KEY_SECRET = window.TWILIO_API_KEY_SECRET;

export const BOOKING_API_URL = window.BOOKING_API_URL || '';
export const CONFERENCE_CONTROL_API_URL = window.CONFERENCE_CONTROL_API_URL || '';
export const PHONE_NUMBER_API_URL = window.PHONE_NUMBER_API_URL || '';

const HEALTH_CHECK_PATH = "/.well-known/health-check";
export const HEALTH_URL_BOOKING_API = `${BOOKING_API_URL}${HEALTH_CHECK_PATH}`;
export const HEALTH_URL_CONTROL_API = `${CONFERENCE_CONTROL_API_URL}${HEALTH_CHECK_PATH}`;
export const HEALTH_URL_PHONE_NUMBER_API = `${PHONE_NUMBER_API_URL}${HEALTH_CHECK_PATH}`;

export const TOKEN_API_URL = `${CONFERENCE_CONTROL_API_URL}/diagnostic-tool/${ACCOUNT_SID}/token`;
